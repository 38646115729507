
<template>
    <div class="franchiseApplicationProtocol">
        <div class="content">
            <div class="title center bold">"村口好物"服务合作协议</div>
            <div class=" indent5">您为在加盟的"村口壹站农村综合便民服务站"（简称服务站）开办"村口好物"服务，特与江苏村口科技有限公司（简称村口科技）下属全资子公司仟村通（上海）科技有限公司签订此协议。此协议各项内容含权责利等与村口科技相关加盟协议等享有同等法律效力。</div>
            <div class=" indent5">第一章 合作内容</div>
            <div class=" indent5">您须根据本协议约定在服务站开展"村口好物"服务业务,充分利用村口科技所提供的团购、商城、直播带货等渠道及平台的资源和运营管理能力，发展服务站所在村、社区的客户拉群，推广销售平台产品，对客户人性化服务等。您在开展服务站业务中须严格按照村口科技、村口科技合作方制定的业务操作流程办理业务。</div>
            <div class=" indent5">村口科技将按照相关考核办法规定的考核内容、计价方式及结算时间向您支付相应的服务费。</div>
            <div class=" indent5">第二章 合作期限</div>
            <div class=" indent5">您同意并确认，本协议的有效期为1年，如双方无异议，自动续期1年，续期次数不限。</div>
            <div class=" indent5">协议期间，如您欲提前终止本协议，需至少提前【3】个月向村口科技提出书面申请并获得同意。</div>
            <div class=" indent5">第三章 权利与义务</div>
            <div class=" indent5">1.村口科技的权利与义务</div>
            <div class=" indent5">1.1权利</div>
            <div class=" indent5">1.1.1村口科技及村口科技合作平台有权自行制定服务站各项管理规定及考核办法，对您在服务站的服务及成果进行管理、检查和监督。</div>
            <div class=" indent5">1.1.2"村口壹站"品牌属于村口科技所有。村口科技合作方品牌属于村口科技合作方所有。</div>
            <div class=" indent5">1.1.3物资：在本协议签署之后，村口科技将协助村口科技合作方向您提供物资（含品鉴物资、样品、储备库存等）及业务专用的机具耗材、业务宣传资料供您作为开展本协议所涉业务使用（设备、物资详见《村口壹站物资签收单》）。如果需要缴纳押金的，具体物资、设备和金额以《村口壹站物资签收单》为准。</div>
            <div class=" indent5">村口科技提供的设备、物资的所有权归村口科技所有；村口科技合作方提供的设备、物资的所有权归村口科技合作方所有；管理、监督权归村口科技所有；使用权归您所有，您应以合理的谨慎和注意确保您提供服务过程中不对自己和他人的人身、财产等权益造成损害；如造成损害的，相关风险和责任由您自行承担。若因您的行为造成的损失已由村口科技及/或其关联公司先行垫付的，村口科技及/或关联公司有权要求您进行偿还，您对此予以认可。</div>
            <div class=" indent5">1.2义务</div>
            <div class=" indent5">1.2.1村口科技将协助您取得村口科技合作方授权开展"村口好物"服务的许可。</div>
            <div class=" indent5">1.2.2为实施和开展服务站业务，村口科技或村口科技协调村口科技合作方提供本协议本章中约定的设备、物资。</div>
            <div class=" indent5">1.2.3村口科技负责或协调村口科技合作方依照本协议约定提供相应的业务开展协助，包括：取得产品知识、业务技能、风险防范知识培训等。</div>
            <div class=" indent5">2.您的权利与义务</div>
            <div class=" indent5">2.1权利</div>
            <div class=" indent5">2.1.1获得村口科技、村口科技合作方的协助，包括：</div>
            <div class=" indent5">2.1.1.1本协议本章1.1.3中村口科技合作方所提供设备、物资的使用权。</div>
            <div class=" indent5">2.1.1.2本协议本章1.2.3中村口科技、村口科技合作方所提供的协助。</div>
            <div class=" indent5">2.1.2根据业务合作协议约定开展专项业务。</div>
            <div class=" indent5">2.1.3获取相关考核办法所约定的服务费。</div>
            <div class=" indent5">2.1.4本协议到期后，在同等条件下，您拥有优先续签合作协议的权利。</div>
            <div class=" indent5">2.2义务</div>
            <div class=" indent5">2.2.1您须遵守并执行村口科技各项管理规定及考核办法。</div>
            <div class=" indent5">2.2.2您不得以村口科技公司及其相关品牌名义擅自销售非村口科技授权经营的产品。</div>
            <div class=" indent5">2.2.3您须妥善管理和维护相关设备、物资（含品鉴物资、样品、储备库存等），若因管理不善造成损坏、丢失等各类损失的，您须按照《村口壹站物资签收单》及其它约定中价格赔偿。</div>
        </div>
        <div class="footer" v-show="signatureShow">
            <van-button type="info" color="#3F7C53" @click="signatureClick">签名</van-button>
        </div>
    </div>
</template>

<script>
    import {COMPANY_NAME} from '@/utils/constantNum'
    import { Button } from 'vant'
    import common from '@/utils/common'
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name:'signEcommerce',
        components:{
            [Button.name]: Button
        },
        data() {
            return {
                signatureShow: false,
                agreementParams: {},
                companyName:COMPANY_NAME,
            }
        },
        mounted(){
            this.$nextTick(()=>{
                this.signatureShow = this.$route.query.sign == 'sign' ? true : false
                if(this.$route.query.agreementParams){
                    this.agreementParams = JSON.parse(this.$route.query.agreementParams)
                }
            })
            uwStatisticAction('/agreement/signEcommerce','《村口好物服务合作协议》')
        },
        methods: {
            signatureClick() {
                this.$router.push({name:'signatureFour', query:{stationNo: this.$route.query.stationNo}})
            }
        }
    }
</script>

<style lang="less" scoped>
    .franchiseApplicationProtocol{
        background: #f5f5f5;
        font-size: 0.3rem;
        box-sizing: border-box;
        padding: 20px 0.3rem 100px 0.3rem ;
        -webkit-overflow-scrolling: touch;
        .content{
            .title{
                font-size: 0.4rem;
            }
            .bold{
                font-weight: bold
            }
            .center{
                text-align: center;
            }
            .indent5{
                text-indent: 0.6rem;
            }
            .mt3{
                margin-top: 0.3rem;
            }
            .underline{
                text-decoration: underline;
            }
        }
        .footer{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            .van-button{
                width: 100%;
            }
        }
    }
</style>
